<template>
  <Modular
    :ListData="ListData"
    :ActiveList="ActiveList"
    @changeActiveList="changeActiveList"
    @changeCurrentPage="changeCurrentPage"
    :LiImgData="LiImgData[ActiveList]"
    title="我们善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的产品"
    subTitle="招标信息"
    :CurrentPage="CurrentPage"
    type="工程类"
  />
  <!-- 我们善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的产品 -->
</template>


<script>
import Modular from "@/components/Modular/Modular";
import { reactive, computed } from "vue";
import imgData from "@/data";
import { mainStore, stateStore } from "@/store";
import { storeToRefs } from "pinia";
export default {
  name: "Engineering",
  components: { Modular },
  setup() {
    // let ActiveList = ref("tender_bulletin");
    const stateOfStore = stateStore();
    let ActiveList = computed(() => {
      return storeToRefs(stateOfStore).EngineeringActive;
    });
    const changeActiveList = (value) => {
      stateOfStore.clearCurrentPage('Engineering')
      stateOfStore.setEngineeringActive(value);
    };

    const ListData = [
      {
        title: "招标公告",
        code: "tender_bulletin",
      },
      {
        title: "变更公告 ",
        code: "correctional_bulletin",
      },
      // {
      //   title: "答疑澄清",
      //   code: "clarify",
      // },
      {
        title: "中标候选人公示",
        code: "win_candidate_publicity",
      },
      {
        title: "中标公告",
        code: "win_notice",
      },
      {
        title: "异常公告",
        code: "failed_notice",
      },
    ];
    let LiImgData = computed(() => {
      const store = storeToRefs(mainStore());
      return store.NoticeOfEngineering;
    });
    const CurrentPage = computed(() => {
      return storeToRefs(stateOfStore).EngineeringcurrentPage;
    });
    const changeCurrentPage = (type) => {
      if (type == "pre") {
        stateOfStore.preEngineeringcurrentPage();
      } else if (type == "next") {
        stateOfStore.nextEngineeringcurrentPage();
      }
    };
    const data = reactive({
      ListData,
      LiImgData,
      ActiveList,
      changeActiveList,
      CurrentPage,
    });
    return {
      ...data,
      ...imgData,
      changeCurrentPage,
    };
  },
};
</script>