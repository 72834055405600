<template>
  <div ref="helpCenterAllWrap" class="helpCenter_all_wrap">
    <div class="helpCenter_wrap">
      <div class="helpCenter_subtitle">帮助中心</div>

      <div class="helpCenter_line">
        <div class="helpCenter_line_left"></div>
        <div class="helpCenter_line_right"></div>
      </div>

      <!-- <div class="helpCenter_title">
        公司善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的互联网产品
      </div> -->

      <div class="helpCenter_content_wrap">
        <!-- top -->

        <div class="helpCenter_table_title">
          <!-- 使用指南按钮 -->
          <div
            class="helpCenter_content_left_top_title"
            @click="changeShow('Guide')"
            :style="showGuide ? 'color:#009FFF' : ''"
          >
            {{
              GuideData.CategoryData
                ? GuideData.CategoryData.categoryName
                  ? GuideData.CategoryData.categoryName
                  : "使用指南"
                : "使用指南"
            }}
          </div>

          <!-- 工具下载按钮 -->
          <div
            class="helpCenter_content_right_title"
            @click="changeShow('ToolsDown')"
            :style="showToolsDown ? 'color:#009FFF' : ''"
          >
            {{
              ToolsDownData.CategoryData
                ? ToolsDownData.CategoryData.categoryName
                  ? ToolsDownData.CategoryData.categoryName
                  : "工具下载"
                : "工具下载"
            }}
          </div>

          <!-- 视频教程按钮 -->
          <div
            class="helpCenter_content_left_bottom_title"
            @click="changeShow('manipulationVideo')"
            :style="showmanipulationVideo ? 'color:#009FFF' : ''"
          >
            {{
              manipulationVideoData.CategoryData
                ? manipulationVideoData.CategoryData.categoryName
                  ? manipulationVideoData.CategoryData.categoryName
                  : "视频使用教程"
                : "视频使用教程"
            }}
          </div>
        </div>
        <div class="helpCenter_content_box">
          <!-- 指南 -->
          <div class="helpCenter_content_left_top_content" v-show="showGuide">
            <div v-for="guideitem in GuideData.ArticleData" :key="guideitem">
              <p
                v-for="item in guideitem"
                :key="item"
                @click="setHtml(item)"
                style="cursor: pointer"
              >
                {{ item.title }}
              </p>
            </div>
          </div>

          <!-- 工具 -->
          <div class="helpCenter_content_right_content" v-show="showToolsDown">
            <div
              class="helpCenter_content_right_content_item_wrap"
              v-for="(item, index) in ToolsDownData.ArticleData
                ? ToolsDownData.ArticleData
                : []"
              :key="index"
            >
              <div
                class="helpCenter_content_right_content_item"
                style="cursor: pointer"
                v-for="(sitem, sindex) in item"
                :key="sindex"
                @click="toHref(sitem.attachList[0].name)"
              >
                <p>{{ sitem.title }}</p>
                <div class="helpCenter_content_right_content_item_img_wrap">
                  <img :src="HelpCenterDown" style="width: 100%" />
                </div>
              </div>
            </div>
          </div>

          <!-- 视频 bottom -->
          <div
            class="helpCenter_content_left_bottom_content"
            v-show="showmanipulationVideo"
          >
            <div
              class="helpCenter_content_left_bottom_item_wrap"
              v-for="witem in manipulationVideoData.ArticleData"
              :key="witem"
            >
              <a
                class="helpCenter_content_left_bottom_item"
                style="cursor: pointer; color: #999; text-decoration: none"
                v-for="item in witem"
                :key="item.title"
                :href="getUrl(item.attachList[0].name)"
              >
                <!-- @click="toHref(item.attachList[0].link)" -->
                <!-- <h1>
                    {{ item.attachList[0].link }}
                  </h1> -->
                <!-- <img :src="HelpCenterImg" /> -->
                <video
                  @play="handleFullScreen"
                  @ended="handleExitFullScreen"
                  :src="getUrl(item.attachList[0].name)"
                  style="height: 80px; height: 80px"
                  controls="controls"
                ></video>
                <p>{{ item.title }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgData from "@/data";
import { reactive, computed, ref } from "vue";
import { mainStore, stateStore } from "@/store/index";
import { storeToRefs } from "pinia";
import _ from "lodash";
import { useRouter } from "vue-router";
import { scrollToLocation } from "@/util/index";

export default {
  setup() {
    const storeOfstate = stateStore();
    const router = useRouter();
    const store = storeToRefs(mainStore());
    const getCategoryAllData = (
      categoryName,
      length,
      chunk = false,
      chunkLength = 2
    ) => {
      return {
        CategoryData: computed(() => {
          return storeToRefs(store).AllNavData.value.find(
            (v) => v.categoryName == categoryName
          );
        }),
        ArticleData: chunk
          ? computed(() => {
              return storeToRefs(store).Article.value[categoryName]
                ? _.chunk(
                    storeToRefs(store).Article.value[categoryName].slice(
                      0,
                      length
                    ),
                    chunkLength
                  )
                : storeToRefs(store).Article.value[categoryName];
            })
          : computed(() => {
              return storeToRefs(store).Article.value[categoryName]
                ? storeToRefs(store).Article.value[categoryName].slice(
                    0,
                    length
                  )
                : storeToRefs(store).Article.value[categoryName];
            }),
      };
    };
    const GuideData = getCategoryAllData("使用指南", 15, true, 5);
    const manipulationVideoData = getCategoryAllData(
      "视频操作教学",
      12,
      true,
      6
    );
    const ToolsDownData = getCategoryAllData("工具下载", 8, true);
    ToolsDownData.ArticleData.value = ToolsDownData;
    const toHref = (url) => {
      // location.href = url;
      window.open(window.location.origin + "/" + url, "_blank");
    };
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const setHtml = (item) => {
      storeOfstate.setactiveHtml(
        item.content,
        item.title,
        item.createTime,
        item
      );
      window.open(
        router.resolve({
          path: "/InfoView",
          query: { id: item.id, type: item.title },
        }).href,
        "_blank"
      );
    };
    const handleFullScreen = (e) => {
      launchFullscreen(e.target);
    };
    const launchFullscreen = (element) => {
      //此方法不可以在異步任務中執行，否則火狐無法全屏
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      } else {
        var docHtml = document.documentElement;
        var docBody = document.body;
        var videobox = element;
        var cssText = "width:100%;height:100%;overflow:hidden;";
        docHtml.style.cssText = cssText;
        docBody.style.cssText = cssText;
        videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
        document.IsFullScreen = true;
      }
    };
    const changeShow = (type) => {
      // ToolsDown、Guide、manipulationVideo
      switch (type) {
        case "ToolsDown":
          showToolsDown.value = !showToolsDown.value;
          showGuide.value = false;
          showmanipulationVideo.value = false;
          break;
        case "Guide":
          showToolsDown.value = false;
          showGuide.value = !showGuide.value;
          showmanipulationVideo.value = false;
          break;
        case "manipulationVideo":
          showToolsDown.value = false;
          showGuide.value = false;
          showmanipulationVideo.value = !showmanipulationVideo.value;
          break;
      }
    };
    const handleExitFullScreen = (e) => {
      exitFullscreen(e.target);
    };
    const exitFullscreen = (element) => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else {
        var docHtml = document.documentElement;
        var docBody = document.body;
        var videobox = element;
        docHtml.style.cssText = "";
        docBody.style.cssText = "";
        videobox.style.cssText = "";
        document.IsFullScreen = false;
      }
    };
    const showToolsDown = ref(false);
    const showGuide = ref(false);
    const showmanipulationVideo = ref(false);
    const data = reactive({
      manipulationVideoData,
      ToolsDownData,
      GuideData,
    });
    return {
      ...data,
      ...imgData,
      toHref,
      setHtml,
      scrollTop: 0,
      getUrl,
      handleFullScreen,
      handleExitFullScreen,
      changeShow,
      showToolsDown,
      showGuide,
      showmanipulationVideo,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop); //监听滚动条
    // console.log(1, this.$refs.helpCenterAllWrap)
  },
  methods: {
    handleToHelpCenter() {
      let timer = setInterval(() => {
        // let ispeed = Math.floor(
        //   this.$refs.helpCenterAllWrap.getBoundingClientRect().top / 1
        // );
        let ispeed = this.$refs.helpCenterAllWrap.getBoundingClientRect().top;
        console.log("ispeed", ispeed);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        console.log(
          "scrollTop",
          this.scrollTop,
          this.$refs.helpCenterAllWrap.getBoundingClientRect().top
        );
        // if (this.$refs.helpCenterAllWrap.getBoundingClientRect().top === 0) {
        clearInterval(timer);
        // }
      }, 30);
    },
    // 监听到的滚动的距离
    scrollToTop() {
      this.scrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log('this.scrollTop',this.scrollTop);

      // 计算距离顶部的高度，当高度大于300显示回顶部图标，小于300则隐藏
      // if (this.scrollTop > 300) {
      //   this.topShow = true;
      // } else {
      //   this.topShow = false;
      // }
    },
  },
};
</script>

<style scoped>
@import url("./HelpCenter.css");
</style>

