<template>
  <Modular
    :ListData="ListData"
    :ActiveList="ActiveList"
    @changeActiveList="changeActiveList"
    @changeCurrentPage="changeCurrentPage"
    :LiImgData="LiImgData[ActiveList]"
    title="我们善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的产品"
    subTitle="货物服务采购信息"
    :CurrentPage="CurrentPage"
    type="货物类"
  />
  <!-- 我们善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的产品 -->
</template>


<script>
import Modular from "@/components/Modular/Modular";
import { reactive, computed } from "vue";
import imgData from "@/data";
import { mainStore, stateStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  components: { Modular },
  setup() {
    const stateOfStore = stateStore();
    let ActiveList = computed(() => {
      return storeToRefs(stateOfStore).PurchasingActive;
    });
    const ListData = [
      {
        title: "采购公告",
        code: "tender_bulletin",
      },
      {
        title: "变更公告 ",
        code: "correctional_bulletin",
      },
      {
        title: "答疑澄清",
        code: "clarify",
      },
      {
        title: "中标公告",
        code: "win_notice",
      },
      {
        title: "异常公告",
        code: "failed_notice",
      },
      {
        title: "单一来源公示",
        code: "single_publicity",
      },

      // {
      //   title: "招标公告",
      //   code: "tender_bulletin",
      // },
      // {
      //   title: "中标候选人公示",
      //   code: "win_candidate_publicity",
      // },
    ];
    let LiImgData = computed(() => {
      const store = storeToRefs(mainStore());
      return store.NoticeOfServiceAndGoods;
    });
    const CurrentPage = computed(() => {
      return storeToRefs(stateOfStore).PurchasingcurrentPage;
    });
    const changeActiveList = (value) => {
      stateOfStore.clearCurrentPage("Purchasing");
      stateOfStore.setPurchasingActive(value);
    };
    const changeCurrentPage = (type) => {
      if (type == "pre") {
        stateOfStore.prePurchasingcurrentPage();
      } else if (type == "next") {
        stateOfStore.nextPurchasingcurrentPage();
      }
    };
    // let LiImgData = Object.values(imgData.PurchasingImg);
    // LiImgData = LiImgData.map((v, index) => ({
    //   title:
    //     index == 0
    //       ? "水泥"
    //       : index == 1
    //       ? "钢筋"
    //       : index == 2
    //       ? "黄沙"
    //       : "门窗",
    //   url: v,
    //   subTitle:
    //     index == 0
    //       ? "南通海衡钢筋集中采购公告"
    //       : index == 1
    //       ? "南通海衡钢筋集中采购公告"
    //       : index == 2
    //       ? "南通海衡钢筋集中采购公告"
    //       : "南通海衡钢筋集中采购公告",
    //   status: "进行中",
    //   content:
    //     index == 0
    //       ? "南通市海衡招标有限公司供配电工程设备采购预选招标—水泥制品采购项目进行公开招标项目供配电工程设备采购…"
    //       : "南通海衡水利工程已由南通市海门区人民政府同意建设，项目已具备招标条件，现对南通海衡水利工程进行公开招标…",
    //   price: "49800元",
    //   endtime: "2022-08-2",
    //   showRight: index == 0 ? true : false,
    // }));
    const data = reactive({
      ListData,
      ActiveList,
      LiImgData,
      CurrentPage,
    });
    return {
      ...data,
      ...imgData,
      changeActiveList,
      changeCurrentPage,
    };
  },
};
</script>