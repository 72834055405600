<template>
  <div
    class="fixed"
    @mouseenter="clearTimer"
    @mouseleave="startDlog"
    :style="'top:' + top + 'px;' + 'left:' + left + 'px'"
    style="display: block"
    @click="toHref(DlogAdData ? DlogAdData.link : '/')"
    v-if="AdShow && DlogAdData"
  >
    <span @click="closeAd" style="width: 20px; height: 20px">x</span>
    <img
      v-if="DlogAdData ? DlogAdData.attachList : false"
      :alt="DlogAdData.adTitle"
      style="width: 300px; text-align: center"
      :src="
        getUrl(
          DlogAdData
            ? DlogAdData.attachList
              ? DlogAdData.attachList[0].name
              : ''
            : ''
        )
      "
    />
    <!-- max-width: 140px; max-height: 90px; -->
    <p v-else></p>
    <!-- {{ DlogAdData.adTitle }} -->
    <!-- > 0 ? DlogAdData[0].adTitle : "广告位招租"  -->
  </div>
</template>
  
  <script>
import { ref, onBeforeMount, reactive, computed, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { DlogStore, mainStore } from "@/store";
export default {
  setup() {
    const store = DlogStore();
    const timerId = ref(null);
    // const top = computed(() => "top:" + storeToRefs(store).top.value + "px");
    // const left = computed(() => "left:" + storeToRefs(store).left.value + "px");
    const top = computed(() => storeToRefs(store).top);
    const left = computed(() => storeToRefs(store).left);
    const AdShow = ref(true);
    const closeAd = (event) => {
      AdShow.value = false;
      event.stopPropagation();
    };
    const startDlog = () => {
      let iw = 300; //图片的宽度
      let ih = 200; //图片的高度
      let ws = parseInt(left.value.value); //当前图片与浏览器页面左边框距离
      let hs = parseInt(top.value.value); //当前图片与浏览器页面上边框距离
      let screenw = window.innerWidth; //浏览器页面宽度
      let screenh = window.innerHeight; //浏览器高度
      let cw = screenw - iw; //图片left属性最大值
      let ch = screenh - ih; //图片top属性最大值
      let wss = 1; //每次水平移动距离
      let hss = 1; //每次垂直移动距离
      timerId.value = setInterval(() => {
        // console.log(
        //   iw,
        //   ih,
        //   ws,
        //   hs,
        //   screenw,
        //   screenh,
        //   cw,
        //   ch,
        //   wss,
        //   hss,
        //   "setInterval"
        // );
        ws += wss;
        hs += hss;
        if (hs > ch) {
          hs = ch;
          hss = -hss;
        }
        if (hs <= 0) {
          hss = -hss;
        }
        if (ws > cw) {
          ws = cw;
          wss = -wss;
        }
        if (ws <= 0) {
          wss = -wss;
        }
        store.setData(hs, ws);
        // top.value = hs + "px";
        // left.value = ws + "px";
      }, 15);
    };
    const clearTimer = () => {
      clearInterval(timerId.value);
    };
    const toHref = (link) => {
      window.open(link, "_blank");
    };
    onBeforeMount(() => {
      startDlog();
    });
    onBeforeUnmount(() => {
      clearTimer();
    });
    const DlogAdData = computed(() => storeToRefs(mainStore()).DlogAd.value);
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const data = reactive({
      top,
      left,
    });
    return {
      startDlog,
      clearTimer,
      DlogAdData,
      toHref,
      ...data,
      AdShow,
      closeAd,
      getUrl,
    };
  },
};
</script>
  
<style scoped>
@import url("./DlogAd.css");
</style>
  
  