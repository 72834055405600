<template>
  <div class="index_Ad_banner">
    <el-carousel :interval="5000">
      <el-carousel-item v-for="item in AdData" :key="item">
        <div
          class="index_Ad_banner_content"
          style="cursor: pointer"
          :style="`background-image:url('${
            item.attachList.length > 0 ? getUrl(item.attachList[0].name) : ''
          }');`"
          @click="toHref(item.link)"
        >
          <h1>{{ item.adTitle }}</h1>
          <h3>{{ item.materialUrl }}</h3>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { mainStore } from "@/store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const AdData = computed(() => storeToRefs(mainStore()).AdData);
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const toHref = (link) => {
      return window.open(
        link != "/" && link.indexOf("http") < 0 ? "https://" + link : link,
        "_blank"
      );
    };
    const data = reactive({
      AdData,
    });
    return {
      ...data,
      getUrl,
      toHref,
    };
  },
};
</script>
<style scoped>
@import url("./AdBanner.css");
</style>